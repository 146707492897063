.height-200 {
    height: 200px;
}

.auth-form {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    margin-top: -225px;
}

.auth-form .forgot-password {
    float:right;
    font-size: 80%;
    position: relative;
    top:-10px;
}

.auth-form .input-group.login {
    margin-bottom: 25px;
}

.auth-form .form-group.submit {
    margin-top: 10px;
}

.auth-form .panel-body {
    padding-top: 30px;
}

.auth-form .info {
    border-top: 1px solid#888;
    padding-top: 15px;
    font-size: 85%;
}

#realization-oil-product .shifts.island,
#oil-turnover .shifts.island,
#balances-varieties .shifts.island,
#balances .shifts.island,
#fuel-dispensers-counters .shifts.island {
    width: 100%;
    margin: 0;
}

#realization-oil-product .shifts.island tbody tr,
#oil-turnover .shifts.island tbody tr,
#balances-varieties .shifts.island tbody tr,
#balances .shifts.island tbody tr,
#fuel-dispensers-counters .shifts.island tbody tr {
    cursor: pointer;
}

#realization-oil-product .realization-oil-product.island,
#oil-turnover .oil-turnover.island,
#balances .reservoirs.island,
#balances-varieties .balances-varieties.island,
#fuel-dispensers-counters .fuel-dispensers-counters.island,
#period-realization .period-realization.island {
    position: relative;
    width: 100%;
    margin: 15px 0 0 0;
}

#balances-varieties .balances-varieties.island,
#balances .reservoirs.island table,
#oil-turnover .oil-turnover.island {
    max-width: 1000px;
}

#realization-oil-product .realization-oil-product.island {
    max-width: 1100px;
}

#balances .reservoirs.island .reservoirs-canvases canvas,
#balances-varieties .reservoirs-canvases canvas{
    margin: 0 12px;
    cursor: pointer;
}

.gray-lighter {
    background: #eee;
}

#realization-oil-product .shifts.island .header,
#oil-turnover .shifts.island .header,
#balances .shifts.island .header,
#balances-varieties .shifts.island .header,
#fuel-dispensers-counters .shifts.island .header {
    position: relative;
}

#realization-oil-product .shifts.island .header > div,
#oil-turnover .shifts.island .header > div,
#balances .shifts.island .header > div,
#balances-varieties .shifts.island .header > div,
#fuel-dispensers-counters .shifts.island .header > div {
    padding-top: 1px;
    padding-bottom: 5px;
}

#realization-oil-product .shifts.island .header > div > div > small,
#oil-turnover .shifts.island .header > div > div > small,
#balances .shifts.island .header > div > div > small,
#balances-varieties .shifts.island .header > div > div > small,
#fuel-dispensers-counters .shifts.island .header > div > div > small {
    position: absolute;
    top: 33px;
    left: 51px;
    font-weight: normal;
    line-height: 1;
    color: #777;
}

#realization-oil-product .realization-oil-product table thead th:nth-child(4),
#realization-oil-product .realization-oil-product table thead th:nth-child(5) {
    min-width: 100px;
}

@media all and (max-width: 1060px) {
    #realization-oil-product .realization-oil-product table thead th:nth-child(4),
    #realization-oil-product .realization-oil-product table thead th:nth-child(5) {
        min-width: 0;
    }
}

#analytics-dynamic-realization .fuel-type.island .checkbox {
    padding: 5px;
}

.dateShifts {
    margin-right: 10px;
    display: inline-block;
}

.a-excel {
    display: inline-block;
    margin-bottom: 15px;
}

.shifts-collapsed button {
    color: #777;
    background-color: transparent;
    border: none;
    outline: none;
}

.grid-line {
    stroke: black;
    shape-rendering: crispEdges;
    stroke-opacity: .2;
}

.weekend {
    fill: red;
    stroke: none;
    opacity: .1;
}

#sidebar li.sidebarGroup {
    color: #adadad;
}

#sidebar li label.open,
#sidebar li.sidebarGroup.active {
    color: #fff!important;
}

#sidebar li label:hover {
    color: white;
}

#sidebar li.sidebarGroup {
    color: #adadad;
}

#sidebar li ul.sidebar-dropdown li.check-list {
    padding: 1px 0 1px 20px;
}

#sidebar li label {
    display: block;
    color: #adadad;
    font-size: 13px;
    white-space: nowrap;
}

#sidebar li label.group {
    float: left;
    padding: 6px 0 6px 10px;
    height: 30px;
}

#sidebar li label.group input {
    margin: 2px 0 0 -20px;
}

#sidebar label.open .sidebar-dropdown {
    display: block;
}

#sidebar {
    padding: 0;
}

li.sidebarGroup > div.checkbox {
    height: 30px;
    margin-bottom: 0;
}

input[type=checkbox]:checked {
    color: #fff;
}

#sidebar li a {
    display: block;
    color: #adadad;
    font-size: 13px;
    margin-left: 0;
    height: 30px;
    white-space: nowrap;
    line-height: 30px;
}

#sidebar ul.sidebar-dropdown li:hover,
#sidebar ul li.sidebarGroup label:hover,
#sidebar ul li.sidebarGroup div.checkbox:hover,
#sidebar ul li.sidebarGroup div:hover,
#sidebar ul li.sidebarGroup a:hover {
    background: #323135;
}

#sidebar ul li.sidebarGroup > a {
    padding-left: 10px;
}

#sidebar ul li.sidebarGroup div > a {
    padding-left: 10px;
    display: inline-block;
    width: 270px;
}

#sidebar ul li.sidebarGroup.active div > a {
    color: #fff;
}

#sidebar ul > li.open > a,
#sidebar li.active a {
    color: #adadad;
}

#sidebar ul > li.active > a,
#sidebar li.active > a {
    color: #fff;
}

#sidebar ul.sidebar-dropdown li:hover a,
#sidebar ul.sidebar-dropdown li:hover label {
    color: #fff;
}

#sidebar .checkbox,
#sidebar .radio {
    margin: 0;
    position: relative;
    display: block;
}

#sidebar input[type=checkbox],
#sidebar input[type=radio] {
    margin: 2px 0 0 -20px;
    line-height: normal;
}

#sidebar .checkbox label,
#sidebar .radio label {
    min-height: 0;
    padding: 5px 0;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}

.scroll-up {
    background-color: black;
    border: solid 2px black;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    opacity: 0.3;
    position: fixed;
    bottom: 50px;
    right: 25px;
    display: none;
    cursor: pointer;
    z-index: 1100;
}

div.scroll-up span.glyphicon-chevron-up {
    color: white;
    font-size: 18px;
    padding: 7px 10px;
}

.scroll-up:hover {
    opacity: 0.7;
}

.version {
    padding-top: 13px;
    text-align: right;
}

div.chart-setting {
    overflow: hidden;
    width: 170px;
}

div.fuel-lines {
    display: flex;
    flex-wrap: wrap;
}

.space-between {
    justify-content: space-between;
}

div.fuel-line {
    width: 100px;
    margin: 15px;
    display: inline-block;
}

div.color-fuel {
    margin-right: 10px;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    float: left;
}

div.label-fuel input {
    display: none;
}

div.label-fuel label {
    margin: 0;
    display: block;
    font-weight: normal;
    cursor: pointer;
    opacity: 0.3;
}

div.label-fuel input[type=checkbox]:checked + label {
    opacity: 1;
}

div.label-fuel input[type=checkbox]:hover + label div.color-fuel {
    transform: scale(1.2);
}

div.fuel-line:hover {
    opacity: 1;
}

#analytics-dynamic-realization .island {
    margin: 0 0 10px 0!important;
}

div.refresh-axis {
    display: inline-block;
    cursor: pointer;
}

i.glyphicon-refresh {
    background-color: #af88b9;
    color: white;
    position: relative;
    border-radius: 50%;
    padding: 8px;
    margin: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

div.refresh-axis:hover i.glyphicon-refresh {
    transform: scale(1.1);
}

p {
    color: #999;
    font-weight: 300;
    margin-top: 20px;
}

div.refresh-axis {
    margin-left: 15px;
}

div.all-fuel-lines {
    font-size: 12px;
    letter-spacing: 0.3px;
    margin-top: 10px;
}

div.all-fuel-lines span {
    float: right;
    margin-right: 20px;
    border-bottom: 1px dashed #000;
    cursor: pointer;
}

div.all-fuel-lines span:hover {
    opacity: 0.7;
}

div.charts.island {
    width: auto;
    height: auto;
}

div#analytics-dynamic-realization {
    margin-bottom: -10px;
    overflow: hidden;
}

div#analytics-dynamic-realization .row {
    margin-right: 0;
    margin-left: -15px;
}

div#analytics-dynamic-realization #reload-content {
    overflow: auto;
}

svg {
    width: 900px;
    height: 510px;
    cursor: pointer;
}

@media (max-width: 1024px) {
    svg {
        width: 592px;
        height: 360px;
    }

    div.all-fuel-lines {
        overflow: hidden;
    }

    div.all-fuel-lines span:hover {
        opacity: 1;
    }

    .scroll-up:hover {
        opacity: 0.3;
    }

    #sidebar ul.sidebar-dropdown li:hover,
    #sidebar ul li.sidebarGroup label:hover,
    #sidebar ul li.sidebarGroup div.checkbox:hover,
    #sidebar ul li.sidebarGroup a:hover {
        background: none;
    }

    div.pushpin:hover,
    #sidebar li label:hover,
    #sidebar ul.sidebar-dropdown li:hover a,
    #sidebar ul.sidebar-dropdown li:hover label {
        color: #adadad;
    }

    #sidebar li ul.sidebar-dropdown li.check-list {
        padding: 1px 0 1px 50px;
    }

    #sidebar.collapse li ul.sidebar-dropdown li a {
        margin-left: 30px;
    }
}

@media (min-width: 450px) and (max-width: 768px) {
    div.form.island .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    div.form.island .form-group {
        display: inline-block;
    }

    div.form-group {
        margin-bottom: 0;
    }

    input.form-control {
        display: inline-block;
        width: auto;
    }

    .btn {
        vertical-align: top;
    }

    div.refresh-axis:hover i.glyphicon-refresh {
        transform: scale(1);
    }

    .form.island .content .form-inline label.binding {
        display: inline-block;
    }

    .checkbox-text {
        padding: 1px 0 0 60px;
    }
}

div.chart-type,
div.chart-type div.select-title,
div.chart-type div.select-block {
    display: inline-block;
}

div.chart-type,
div.chart-type div.select-block select {
    cursor: pointer;
}

div.chart-type {
    margin-left: 50px;
}

div.select-block {
    margin-left: 5px;
}

div.chart-type label {
    font-weight: 400;
}

div.other-settings {
    margin: 15px 0;
}

.form.island .content .form-inline {
    margin-top: 10px;
}

.binding input,
#sidebar .checkbox input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    margin: 10px 0 0 20px;
}

.binding-text {
    position: relative;
    padding: 0 0 0 60px;
    cursor: pointer;
}

.binding-text:before {
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    width: 50px;
    height: 26px;
    border-radius: 13px;
    background: #CDD1DA;
    box-shadow: inset 0 2px 3px rgba(0,0,0,.2);
    transition: .2s;
}

.binding-text:after {
    content: '';
    position: absolute;
    top: -2px;
    left: 2px;
    width: 22px;
    height: 22px;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0 2px 5px rgba(0,0,0,.3);
    transition: .2s;
}

.binding input:checked + .binding-text:before {
    background: #9FD468;
}

.binding input:checked + .binding-text:after {
    left: 26px;
}

button {
    outline: none!important;
}

.collection-hover {
    stroke-width: 4px;
}

.voronoi path {
    fill: none;
    pointer-events: all;
    stroke: none;
    stroke-opacity: 0.2;
}

.circle-hover {
    stroke-width: 8px;
}

div.dot-info {
    background-color: black;
    color: #fff;
    position: absolute;
    text-align: center;
    padding: 6px;
    font: 11px sans-serif;
    border: 0;
    border-radius: 8px;
    pointer-events: none;
}

.dot-info:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border: solid 5px;
    border-color: transparent black transparent transparent;
}

.chart-title {
    font-size: 16px;
    text-anchor: middle;
}

span.glyphicon-triangle-right,
span.glyphicon-triangle-bottom {
    margin-right: 5px;
}

#sidebar li ul.sidebar-dropdown li {
    padding-left: 35px;
}

input.shops {
    display: none;
}

.pseudo-checkbox::before {
    content: "\00A0";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    text-align: center;
    font-weight: bold;
    color: #fff;
}

input.shops:checked + .pseudo-checkbox::before {
    content: "\2713";
}

span.glyphicon.glyphicon-check {
    font-size: 16px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    top: 4px;
    color: #adadad;
}

span.glyphicon.glyphicon-check:hover,
span.glyphicon.glyphicon-check.active {
    color: #fff;
}

@media (max-width: 1024px) {
    span.glyphicon.glyphicon-check {
        top: -7px;
    }
}

@media (max-width: 480px) {
    label.binding {
        display: block;
    }
}

.w-100 {
    width: 100%!important;
}

th {
    text-align: center!important;
    vertical-align: top!important;
}

.period .form-group {
    margin-bottom: 15px;
}

#period-realization {
    overflow: hidden;
}

.report-button {
    display: block;
    float: none;
}

.azs-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding-right: 10px;
}

#sidebar a {
    vertical-align: middle;
}

.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.check-mark {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 2px;
    border: 2px solid rgba(0,0,0,0.54);
}

.checkbox-container input:checked ~ .check-mark {
    background-color: #000;
}

.check-mark:after {
    content: "";
    position: absolute;
    opacity: 0;
}

.checkbox-container input:checked ~ .check-mark:after {
    opacity: 1;
}

.checkbox-container .check-mark:after {
    top: -1px;
    left: 4px;
    width: 6px;
    height: 13px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.pos-groups {
    width: 230px;
    cursor: pointer;
}

.table-header {
    display: flex;
    flex: 1;
    border-bottom: none!important;
}

.table-header h4 {
    flex: 1;
}

#control-state .form.island {
    width: 100%;
}

#control-state table th {
    text-align: left!important;
}

#control-state table tbody tr:hover {
    cursor: pointer;
    background-color: #f5f5f5!important;
}

table.table-selected tr.checked {
    background-color: #f5f5f5!important;
}

.alternate-header {
    background-color: rgba(255,82,82,0.2);
    align-items: center;
    height: 62px;
}

.select-count {
    flex: 1;
}

#control-state .island {
    position: relative;
}

.loader-ajax-container {
    position: absolute;
    top: 50%;
    left: calc(50% - 30px);
}

@media all and (max-width: 959px) {
    header .title .pos-name {
        display: none;
    }
}

.collection-comment {
    max-width: 250px;
    padding-left: 20px!important;
}

.collection-direction {
    width: 60px;
}

.collection-protocol-table {
    max-width: 1100px;
}

.collection-operator-name {
    max-width: 150px;
}

#catalogue-goods h4 {
    font-size: 24px;
}

#catalogue-goods .island {
    width: 100%;
}

#catalogue-goods .content {
    position: relative;
    min-height: 400px;
    margin-top: 0 !important;
}

#catalogue-goods .form-search {
    display: inline-block;
    margin-right: 30px;
}

#catalogue-goods .form-search .glyphicon-remove {
    position: absolute;
    z-index: 9;
    right: 90px;
    top: 9px;
    cursor: pointer;
}

#catalogue-goods .goods-search-input {
    width: 300px;
    padding-right: 25px;
}

#catalogue-goods table tbody tr:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,0.08) !important;
}

#catalogue-goods .table-goods tr.header {
    background-color: rgba(0,0,0,0.04);
}

#catalogue-goods .table-goods .glyphicon-folder-close {
    color: gold;
    margin-right: 5px;
}

#catalogue-goods .table-goods .glyphicon-pencil {
    font-size: 16px;
    padding: 2px 10px 0 10px;
}

#catalogue-goods .table-goods td.bar-codes {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#catalogue-goods .table-goods {
    position: relative;
    border-collapse: collapse;
}

#catalogue-goods .table-header {
    display: flex;
    align-items: center;
    background: white;
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 15px 0;
}

#catalogue-goods .table-goods th {
    background: white;
    position: sticky;
    top: 76px;
    z-index: 10;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.4);
}

#catalogue-goods .table-goods th.bar-codes {
    width: 300px;
}

#catalogue-goods .table-goods th.goods-fix-td {
    width: 200px;
}

#catalogue-goods .table-goods .actions {
    width: 350px;
}

#catalogue-goods .table-goods tr.searched-row {
    background-color: #337ab7 !important;
}

#catalogue-goods .table-goods .goods-name {
    padding-left: 50px;
}

#catalogue-goods .table-goods .goods-name .glyphicon-minus {
    margin-right: 10px;
}

#catalogue-goods .table-goods .edit-hover:hover {
    transform: scale(1.2);
}

#catalogue-goods .cssload-container,
#group-goods-modal .cssload-container,
#group-goods-modal-delete .cssload-container,
#goods-modal-add-or-edit .cssload-container,
#goods-modal-delete .cssload-container,
#send-data-modal .cssload-container {
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
}

#group-goods-modal .modal-content {
    position: relative;
}

#group-goods-modal .form-group.has-error input,
#goods-modal-add-or-edit .form-group.has-error input,
#goods-modal-add-or-edit .form-group.has-error select {
    border-color: red;
}

#group-goods-modal .form-group.has-error label,
#goods-modal-add-or-edit .form-group.has-error label {
    color: red;
}

#group-goods-modal .form-group.has-error span,
#goods-modal-add-or-edit .form-group.has-error span {
    color: red;
}

#goods-modal-add-or-edit .modal-body {
    overflow: hidden;
}

#goods-modal-add-or-edit .check-box-modal-goods {
    display: block;
    height: 34px;
    margin: 0;
}

#goods-modal-add-or-edit .nav.nav-tabs {
    margin-bottom: 15px !important;
}

#goods-modal-add-or-edit .form-group {
    min-height: 80px;
}

#goods-modal-add-or-edit .nav-tabs a.has-error {
    color: red;
}

#goods-modal-add-or-edit .nav-tabs .badge-danger {
    background-color: red;
}

#goods-modal-add-or-edit .bar-codes-list {
    height: 130px;
    resize: none;
}